/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */

import { Button, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Snackbar from '@mui/material/Snackbar';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';


import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import axios from 'axios';

import React, { useEffect, useState } from 'react'
import { styled } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import dayjs from 'dayjs';
import 'dayjs/locale/uk'
import { ukUA } from '@mui/x-date-pickers/locales';



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});




const Form = styled(FormControl)({
    minWidth: '220px',
    marginBottom: '15px',
    marginTop: '10px',
});

const Summary = styled(Paper)(({ theme }) => ({
    padding: '10px 30px',
    [theme.breakpoints.down('sm')]: {
        padding: '10px',
        '& .MuiTypography-body1': {
            fontSize: '0.8em',
        },
    },
}));

const Container = styled(Grid)(({ theme }) => ({
    padding: '20px 30px 0',
    width: '100%',
    justifyContent: 'space-between',

    [theme.breakpoints.down('md')]: {

        alignContent: 'center',
        flexDirection: 'column',
        padding: '30px 0 0',
    },

}));

const SummaryTimes = styled(Grid)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '10px',
    width: '100%',
    '& .MuiFormControl-root': {
        [theme.breakpoints.down('sm')]: {
            width: '45%',
        },
    },
}));

const Calendar = styled(StaticDatePicker)({
    '& .MuiPickersCalendarHeader-switchHeader': {
        display: 'none', // Hide the year selection dropdown
    },
    '& .MuiDialogActions-root': {
        display: 'none', // Hide the footer with Ok and Cancel buttons
    },
    '& .MuiPickersToolbar-root': {
        display: 'none',
    },
    alignItems: 'center',
});

const Prices = styled(Paper)({
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '10px 0px',
    width: '100%'
});

const Contact = styled(Prices)({
    alignItems: 'center',
    padding: '5px',
    marginBottom: '15px',
});

const Price = styled(Typography)({
    textAlign: 'left',
});

const SummaryTimePicker = styled(Form)(({ width }) => ({
    minWidth: width,
}));

const TimeIcon = styled(AccessTimeIcon)({
    width: '45px',
    height: 'auto',
    '@media (max-width: 600px)': {
        display: 'none',
    },
});

const PriceSet = styled(Grid)(({ theme }) => ({
    padding: '0 30px',
    [theme.breakpoints.down('sm')]: {
        padding: '0 10px',
    },
}));

const Input = styled(TextField)({
    margin: '10px',
    width: '95%',
});

const Subtitle = styled(Typography)({
    margin: '10px',
    width: '95%',
});


const InputContainer = styled('form')({
    width: '100%',
});

const Found = styled(Typography)({
    color: 'green',
    textAlign: 'center',
});

const Book = styled(Button)({
    margin: '15px 0 10px',
    backgroundColor: 'green',
    color: 'white',
    '&:hover': {
        backgroundColor: '#006500',
    },
});

const Subcontainer = styled(Grid)({
    marginBottom: '40px',
    '& .MuiPickersBasePicker-container': {
        alignItems: 'center',
    },
});

const NotFoundTitle = styled(Typography)({
    marginBottom: '1em',
});

const NotFoundSubtitle = styled(Typography)({
    marginBottom: '3em',
});

const DiscrepancyText = styled(Typography)({
    color: 'orange',
});

const FieldSubtitle = styled(Typography)({
    color: 'gray',
});

const GoBack = styled('div')({
    backgroundColor: '#e5e8eb',
    padding: '10px 40px',
});

const GoBackLink = styled('p')({
    cursor: 'pointer',
    '&:hover': {
        textDecoration: 'underline',
    },
});

const TimeSelect = styled(Select)({
    '.MuiSelect-icon': {
        display: props => (props.disabled ? 'none' : ''),
    },
});

const Language = styled(Subcontainer)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    padding: '20px 30px 0',
    '.MuiInput-input': {
        width: '50px',
        fontSize: '20px',
    },
    [theme.breakpoints.down('md')]: {
        padding: '30px 0 0',
        maxWidth: '83.333333%',
        margin: 'auto'
    },
}));

export default function MaterialUIPickers() {
    const [selectedDate, setSelectedDate] = useState(dayjs(new Date()));
    const [maxDate, setMaxDate] = useState(dayjs(new Date('2122-05-21')));
    const [disabeledDays, setDisabeledDays] = useState([]);
    const [boats, setBoats] = useState([])
    const [selectedBoat, setSelectedBoat] = useState("00000000-0000-0000-0000-000000000000")
    const [boatsStatus, setBoatsStatus] = useState("");
    const [coaches, setCoaches] = useState([{ id: "00000000-0000-0000-0000-000000000000", name: "Без тренера" }])
    const [selectedCoach, setSelectedCoach] = useState("00000000-0000-0000-0000-000000000000");
    const [coachesStatus, setCoachesStatus] = useState("");
    const [riders] = useState([1, 2, 3, 4, 5, 6])
    const [selectedRiders, setSelectedRiders] = useState(1)
    const [beginners, setBeginners] = useState([0, 1])
    const [selectedBeginners, setSelectedBeginners] = useState(0)
    const [duration, setDuration] = useState([10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120, 125, 130, 135, 140, 145, 150])
    const [selectedDuration, setSelectedDuration] = useState(10)
    const [timeFrames, setTimeFrames] = useState([])
    const [selectedStart, setSelectedStart] = useState("")
    const [selectedEnd, setSelectedEnd] = useState("")
    const [coachPrice, setCoachPrice] = useState()
    const [boatPrice, setBoatPrice] = useState()
    const [totalPrice, setTotalPrice] = useState()
    const [riderName, setRiderName] = useState("")
    const [riderPhone, setRiderPhone] = useState("+380")
    const [riderFound, setRiderFound] = useState(false)
    const [riderBalance, setRiderBalance] = useState(0)
    const [sessionStatus, setSessionStatus] = useState("Not Yet Found") //the others are "Error", "Looking" "Found" and "Not Found"
    const [findValidation, setFindValidation] = useState(true)
    const [paymentType, setPaymentType] = useState("full")
    const [show, setShow] = useState("Tool") //the other is "Checkout"

    const [checkoutError, setCheckoutError] = useState("");

    const [discrepancy, setDiscrepancy] = useState(false)

    const [startTimes, setStartTimes] = useState([])
    const [endTimes, setEndTimes] = useState([])

    const [final, setFinal] = useState({});

    const [successfullyBooked, setSuccessfullyBooked] = useState(false)
    const [errorBooking, setErrorBooking] = useState(false);

    const [lang, setLang] = useState("ua")
    const [coachAlert, setCoachAlert] = useState(false)
    const [comment, setComment] = useState("")

// eslint-disable-next-line
    useEffect(() => {

        const indexBoats = boats.findIndex(object => {
            return object.id === "00000000-0000-0000-0000-000000000000";
        });


        if (indexBoats !== -1) {
            // change name of the found object in the boats state array
            setBoats(boats.map(object => {
                if (object.id === "00000000-0000-0000-0000-000000000000") {
                    object.name = lang === "ua" ? "Будь-який катер" : "Any boat"
                }
                return object;
            }  // end of map
            ))
        }

        const indexCoaches = coaches.findIndex(object => {
            return object.id === "00000000-0000-0000-0000-000000000001";
        });

        const indexCoaches0 = coaches.findIndex(object => {
            return object.id === "00000000-0000-0000-0000-000000000000";
        });

        if (indexCoaches !== -1) {
            // change name of the found object in the coaches state array
            setCoaches(coaches.map(object => {
                if (object.id === "00000000-0000-0000-0000-000000000001") {
                    object.name = lang === "ua" ? "Будь-який тренер" : "Any coach"
                }
                return object;
            }  // end of map
            ))
        }

        if (indexCoaches0 !== -1) {
            // change name of the found object in the boats state array
            setCoaches(coaches.map(object => {
                if (object.id === "00000000-0000-0000-0000-000000000000") {
                    object.name = lang === "ua" ? "Без тренера" : "Without coach"
                }
                return object;
            }  // end of map
            ))
        }
// eslint-disable-next-line
    }, [lang])

    
    useEffect(() => {
        if (sessionStatus === "Found") {
            let finalBoat = boats.map(boat => {
                if (boat.id === selectedBoat) {
                    return boat.name
                }
                return null;
            })

            let finalCoach = coaches.map(coach => {
                if (coach.id === selectedCoach) {
                    return coach.name
                }
                return null;
            })

            let toCompare = { date: selectedDate.toISOString().split('T')[0], boat: finalBoat, coach: finalCoach, riders: selectedRiders, beginners: selectedBeginners, duration: selectedDuration, name: riderName, phone: riderPhone.replace("+", "") }
            if (JSON.stringify(toCompare) === JSON.stringify(final)) {
                setDiscrepancy(false)
            } else {
                setDiscrepancy(true)
            }
        }
        // eslint-disable-next-line
    }, [selectedDate, selectedBoat, selectedCoach, selectedRiders, selectedBeginners, selectedDuration, riderName, riderPhone])

    // eslint-disable-next-line
    useEffect(() => {
        let starts = [];
        timeFrames.forEach(frame => {
            starts.push(frame[0]);
        })
        setStartTimes(starts)
    }, [timeFrames])

    const handleChangeLanguage = (event) => {
        setLang(event.target.value)
    }

    const handleDateChange = async (date) => {
        setSelectedDate(date);
        //console.log(date.toISOString().split('T')[0])
        setCoachesStatus("Getting coaches")
        let coaches = await axios.post('https://api.cityswell.com.ua/api/coach', { day: date.toISOString().split('T')[0] });

        let availableCoaches = coaches.data.coaches;

        if (availableCoaches.length > 0) {
            if (selectedBeginners > 0) {
                setCoaches([...coaches.data.coaches])
            } else {
                setCoaches([{ id: "00000000-0000-0000-0000-000000000000", name: lang === "ua" ? "Без тренера" : "Without coach" }, ...coaches.data.coaches])
            }
            if (selectedCoach === "") {
                setSelectedCoach(coaches.data.coaches[0].id);
            }
            setCoachesStatus("")
        } else {
            if (selectedBeginners > 0) {

                setSelectedCoach("")
                setCoachesStatus("No coaches")
            } else {
                setCoaches([{ id: "00000000-0000-0000-0000-000000000000", name: "Без тренера" }])
                setSelectedCoach("00000000-0000-0000-0000-000000000000")
                setCoachesStatus("")
            }
        }
    };



    let disableDay = (date) => {

        const dis = (day) => date.setHours(0, 0, 0, 0) === dayjs(new Date(day)).setHours(0, 0, 0, 0);

        return disabeledDays.some(dis)
    }

    // eslint-disable-next-line
    useEffect(() => {
        if (riderName.length > 1 && riderPhone.replace("+", "").length > 11 && selectedCoach !== "") {

            setFindValidation(false);
        } else {
            setFindValidation(true);
        }
    }, [riderName, riderPhone, selectedCoach])

    const handleChange = (event) => {
        if (event.target.name === 'payment-type') {
            setPaymentType(event.target.value)
        }
        if (event.target.name === 'boats') {
            setSelectedBoat(event.target.value);
        }
        else if (event.target.name === 'name') {
            setRiderName(event.target.value)
        }
        else if (event.target.name === 'comment') {
            if (event.target.value.length <= 300) {
                setComment(event.target.value)
            } else {
                // If pasted content exceeds the limit, truncate it
                const truncatedValue = event.target.value.slice(0, 300);
                setComment(truncatedValue);
            }
        }
        else if (event.target.name === 'phone') {
            if (event.target.value.replace("+", "").length < 13) {
                // check with regext if string starts with plus or has numbers
                if (event.target.value.match(/^[+-]?\d+$/) || event.target.value === "" || event.target.value === "+") {
                    setRiderPhone(event.target.value);
                }
            }
        }
        else if (event.target.name === 'coaches') {
            setSelectedCoach(event.target.value);
        } else if (event.target.name === 'riders') {
            setSelectedRiders(event.target.value)
            const newSelectedRiders = parseInt(event.target.value, 10);
            setSelectedRiders(newSelectedRiders);

            // Update the beginners array to have values from 0 up to the number of selected riders
            const newBeginnersOptions = Array.from({ length: newSelectedRiders + 1 }, (_, index) => index);
            setBeginners(newBeginnersOptions);

            // If the currently selected number of beginners is greater than the new number of riders,
            // update the selected beginners to the maximum allowed value (which is the new number of riders)
            if (selectedBeginners > newSelectedRiders) {
                setSelectedBeginners(newSelectedRiders);
            }

            // Update the duration options to have values from 10 to 150
            const newDurationOptions = Array.from({ length: 31 - newSelectedRiders * 2 }, (_, index) => index * 5 + newSelectedRiders * 10);
            setDuration(newDurationOptions);

            // If the currently selected duration is greater than the new number of riders * 10 minutes,
            // update the selected duration to the maximum allowed value
            if (selectedDuration < newSelectedRiders * 10) {
                setSelectedDuration(newSelectedRiders * 10);
            }

        } else if (event.target.name === 'beginners') {
            setSelectedBeginners(event.target.value);

            // enfore having a coach on the party with beginners
            if (event.target.value > 0) {



                if (selectedCoach === "00000000-0000-0000-0000-000000000000" && coaches.length > 1) {
                    setSelectedCoach(coaches[1].id);
                    setCoachAlert(true)
                }

                if (selectedCoach === "00000000-0000-0000-0000-000000000000" && coaches.length <= 1) {
                    setSelectedCoach("");
                    setCoachesStatus("No coaches")
                    setCoachAlert(true)
                }

                //delete the no coach option
                let updatedCoaches = coaches.filter(coach => coach.id !== "00000000-0000-0000-0000-000000000000");
                setCoaches(updatedCoaches);

            } else {
                if (coaches.length <= 1) {
                    setCoaches([{ id: "00000000-0000-0000-0000-000000000000", name: "Без тренера" }])
                    setSelectedCoach("00000000-0000-0000-0000-000000000000")
                    setCoachesStatus("")
                }
            }
            //return the no coach option if there are now not beginners in the party
            if (event.target.value === 0 && !coaches.some(coach => coach.id === "00000000-0000-0000-0000-000000000000")) {
                let updatedCoaches = [{ id: "00000000-0000-0000-0000-000000000000", name: (lang === "ua" ? "Без тренера" : "Without coach") }, ...coaches];
                // updatedCoaches.splice(1, 0, { id: "00000000-0000-0000-0000-000000000000", name: (lang === "ua" ? "Без тренера" : "Without coach") });
                setCoaches(updatedCoaches);
            }

        }
        else if (event.target.name === 'duration') {
            setSelectedDuration(event.target.value)
        }
        else if (event.target.name === 'start') {
            setSelectedStart(event.target.value)

            timeFrames.forEach(frame => {
                if (frame[0] === event.target.value) {
                    setSelectedEnd(frame[1])
                    setEndTimes([frame[1]])
                    // console.log(frame)
                    setCoachPrice(frame[2])
                    setBoatPrice(frame[3])
                }
            });


        }
        else if (event.target.name === 'end') {
            setSelectedEnd(event.target.value)
        }
    };

    let resetEverything = () => {
        //RESET EVERYTHING
        setSelectedDate(dayjs(new Date()));
        setSelectedBoat("00000000-0000-0000-0000-000000000000")
        setSelectedCoach("00000000-0000-0000-0000-000000000000")
        setSelectedRiders(1)
        setSelectedBeginners(0)
        setSelectedDuration(10)
        setTimeFrames([])
        setSelectedStart("")
        setSelectedEnd("")
        setCoachPrice()
        setBoatPrice()
        setTotalPrice()
        setRiderName("")
        setRiderPhone("38")
        setSessionStatus("Not Yet Found") //the others are "Error and Not Found"
        setFindValidation(true)
        setRiderFound(false)
        setRiderBalance(0)
        setComment("")
        setCheckoutError(""); // Reset checkout error
    }

    const order = async () => {
        const sendOrder = async () => {
            let order = {
                phone: riderPhone.replace("+", ""),
                startDate: `${selectedDate.toISOString().split('T')[0]} ${selectedStart}`,
                shipId: selectedBoat,
                coachId: selectedCoach,
                sessionDuration: selectedDuration,
                ridersQty: selectedRiders,
                name: riderName,
                comment: comment,
                beginnersQty: selectedBeginners
            }
            return await axios.post("https://api.cityswell.com.ua/api/book", order);
        }

        let bookingStatus = await sendOrder();
        console.log(bookingStatus.data.orderId)

        if (bookingStatus.data.orderId === 0) {
            setErrorBooking(true)
        } else {

        try {

            const response = await axios.post(
                "https://api.monobank.ua/api/merchant/invoice/create",
                {
                    amount: parseInt(totalPrice) * 100, // Amount in the smallest currency unit (e.g., cents)
                    ccy: 980, // Currency code for UAH
                    merchantPaymInfo: {
                        destination: `${lang === "ua" ? "Оплата броні на" : "Booked for"} ${final.date}. ${lang === "ua" ? "Початок сесії:" : "Session start"} ${selectedStart}, ${lang === "ua" ? "загальна тривалість сесії:" : "total session duration"} ${selectedDuration} ${lang === "ua" ? "хвилин" : "minutes"}, ${final.riders} ${lang === "ua" ? "райдерів" : "riders"}`,
                        reference: `${bookingStatus.data.orderId}`, // Reference for the payment
                    },
                    redirectUrl: window.location.origin, // Redirect URL after payment
                    validity: 3600, // Validity of the invoice in seconds (e.g., 1 hour)
                    webhookUrl: "https://api.cityswell.com.ua/api/callback", // Webhook URL for payment notifications
                },
                {
                    headers: {
                        "X-Token": "u2uGdwa-GeeeZci_hVNZh-01TGQImhSkIxG5TUSKOmYo", // Replace with your actual merchant token
                    },
                }
            );
            console.log(response)
            console.log(parseInt(totalPrice) * 100)
            window.location.href = response.data.pageUrl; // Redirect to the checkout link
            setCheckoutError(""); // Clear any previous errors
        } catch (error) {
            console.log(error.response?.data?.errText);
            setCheckoutError(lang === "ua" ? "Сталася помилка при формуванні чекауту" : "An error occurred while generating the checkout link.");
        }
    }

        // Below is a setup that was used for Fondy
        // let bookingStatus = await sendOrder();

        // // console.log(bookingStatus)

        // if (bookingStatus.data.orderId === 0) {
        //     setErrorBooking(true)
        // } else {

        //     var Options = {
        //         options: {
        //             methods: ['card', 'wallets'],
        //             methods_disabled: ['banklinks_eu', 'local_methods'],
        //             card_icons: ['mastercard', 'visa'],
        //             active_tab: 'card',
        //             fields: false,
        //             title: document.title,
        //             link: window.location.origin,
        //             full_screen: true,
        //             button: true,
        //             email: false
        //         },
        //         params: {
        //             merchant_id: 1396424,
        //             order_id: bookingStatus.data.orderId,
        //             required_rectoken: 'y',
        //             currency: 'UAH',
        //             lang: lang === "ua" ? 'uk' : 'en',
        //             locales: ['en', 'uk'],
        //             amount: parseInt(totalPrice) * 100,
        //             order_desc: `${lang === "ua" ? "Оплата броні на" : "Booked for"} ${final.date}. ${lang === "ua" ? "Початок сесії:" : "Session start"} ${selectedStart}, ${lang === "ua" ? "загальна тривалість сесії:" : "total session duration"} ${selectedDuration} ${lang === "ua" ? "хвилин" : "minutes"}, ${final.riders} ${lang === "ua" ? "райдерів" : "riders"}`,
        //             server_callback_url: "https://api.cityswell.com.ua/api/callback"
        //         }
        //     }

        //     if (totalPrice > 0) {
        //         setShow("Checkout");
        //         window.fondy("#checkout-container", Options)
        //             .$on("success", function (model) {
        //                 //   console.log(model.attr("order.order_data"));

        //                 var order_status = model.attr("order.order_data.order_status");

        //                 if (order_status === "approved") {
        //                     // console.log("Order is approved. Do somethng on approve...");
        //                     resetEverything();

        //                 }
        //             })
        //             .$on("error", function (model) {
        //                 // console.log("error event handled");
        //                 var response_code = model.attr("error.code");
        //                 var response_description = model.attr("error.message");
        //                 console.log(
        //                     "Order is declined: " +
        //                     response_code +
        //                     ", description: " +
        //                     response_description
        //                 );
        //                 console.log(model.attr("order.order_data"));
        //             });
        //     } else {
        //         setSuccessfullyBooked(true);
        //     }

        // }


    }

    // eslint-disable-next-line
    useEffect(() => {
        let fetchData = async () => {
            setBoatsStatus("Getting boats");
            setCoachesStatus("Getting coaches");

            try {
                const [calendarResponse, shipsResponse, coachesResponse] = await Promise.all([
                    axios.get('https://api.cityswell.com.ua/api/calendar'),
                    axios.get('https://api.cityswell.com.ua/api/ship'),
                    axios.post('https://api.cityswell.com.ua/api/coach', { day: selectedDate.toISOString().split('T')[0] })
                ]);

                setBoatsStatus("");
                setCoachesStatus("");

                const calendarData = calendarResponse.data;
                const shipsData = shipsResponse.data;
                const coachesData = coachesResponse.data;

                setMaxDate(dayjs(new Date(calendarData.lastDay)));
                setDisabeledDays(calendarData.disabledDays);
                setBoats([...shipsData.ships]);
                setSelectedBoat(shipsData.ships[0].id);
                setCoaches([{ id: "00000000-0000-0000-0000-000000000000", name: lang === "ua" ? "Без тренера" : "Without coach" }, ...coachesData.coaches]);
            } catch (error) {
                console.error('Error fetching data:', error);
                // Handle error gracefully
                if (error.response && error.response.status === 500) {
                    // Show a message or take appropriate action
                    // For example:
                    alert('Internal server error occurred. Please try again later.');
                } else {
                    // For other errors, you might handle them differently
                    // You can display a generic error message or handle them based on the specific error type
                    // For example:
                    alert('An error occurred while fetching data. Please try again later.');
                }
            }
        };


        fetchData();
        // eslint-disable-next-line
    }, [])

    // eslint-disable-next-line
    useEffect(() => {
        if (coachPrice + boatPrice >= riderBalance) {
            if (paymentType === 'part') {
                setTotalPrice((coachPrice + boatPrice - riderBalance) / 2)
            } else {
                setTotalPrice(coachPrice + boatPrice - riderBalance)
            }

        } else {
            setTotalPrice(0)
        }

    }, [coachPrice, boatPrice, riderBalance, paymentType])

    const findSessions = async () => {
        setSelectedEnd("")
        setSelectedStart("")
        const data = {
            day: selectedDate.toISOString().split('T')[0],
            phone: riderPhone,
            "shipID": selectedBoat,
            "coachID": selectedCoach,
            "ridersQty": selectedRiders,
            "beginnersQty": selectedBeginners,
            "sessionDuration": selectedDuration,
            "startDate": selectedDate.toISOString().split('T')[0]
        }

        // console.log(data)

        try {
            setSessionStatus("Looking");
            let send = await axios.post("https://api.cityswell.com.ua/api/session", data);




            if (send.data.isFound && send.data.isFound === true) {
                setRiderFound(true)
                setRiderBalance(send.data.balance)
            } else {
                setRiderFound(false)
                setRiderBalance(0)
            }

            if (send.data.timeFrames?.length > 0) {
                setDiscrepancy(false)
                setSessionStatus("Found");


                setTimeFrames(send.data.timeFrames.map(frame => {
                    return [frame.startTime, frame.endTime, frame.coachPrice, frame.boatPrice]
                }));

                let finalBoat = boats.map(boat => {
                    if (boat.id === selectedBoat) {
                        return boat.name
                    }
                    return null;
                })

                let finalCoach = coaches.map(coach => {
                    if (coach.id === selectedCoach) {
                        return coach.name
                    }
                    return null;
                })

                setFinal({ date: selectedDate.toISOString().split('T')[0], boat: finalBoat, coach: finalCoach, riders: selectedRiders, beginners: selectedBeginners, duration: selectedDuration, name: riderName, phone: riderPhone.replace("+", "") })

            } else {
                setSessionStatus("Not Found")
            }

        } catch (error) {
            setSessionStatus("Error")
            console.error(error)
        }
    }

    let handleClose = (type) => {

        if (type === "success") {
            resetEverything();
            setSuccessfullyBooked(false);
        }
        if (type === "error") {
            setErrorBooking(false);
        }
        if (type === "coachAlert") {
            setCoachAlert(false);
        }
    }
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={lang === "ua" ? "uk" : "en"} localeText={lang === "ua" ? ukUA.components.MuiLocalizationProvider.defaultProps.localeText : "en"}>
            {show !== "Checkout" && <Language item alignItems="flex-end">
                <FormControl size="small">
                    <InputLabel id="demo-simple-select-label">{lang === "ua" ? "Мова" : "Language"}</InputLabel>
                    <Select
                        value={lang}
                        label="Age"
                        onChange={handleChangeLanguage}
                    >
                        <MenuItem value={"ua"}>🇺🇦</MenuItem>
                        <MenuItem value={"en"}>🇺🇸</MenuItem>

                    </Select>
                </FormControl>
            </Language>}
            {
                show === "Tool" && <Container container>
                    <Subcontainer item xs={10} md={4} lg={2}>
                        <Calendar

                            variant="static"
                            disablePast
                            format="MMMM/dd/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            label="Date picker inline"
                            shouldDisableDate={disableDay}
                            value={selectedDate}
                            maxDate={maxDate}
                            onChange={handleDateChange}
                            views={['day']}
                            DatePickerToolbar={() => null}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </Subcontainer>
                    <Subcontainer item xs={10} md={3}>

                        <Grid container direction="column" justifyContent="center">
                            {SelectForm("boats", boats, selectedBoat, lang === "ua" ? "Катер" : "Boat", handleChange, lang, boatsStatus)}
                            {SelectForm("coaches", coaches, selectedCoach, lang === "ua" ? "Тренер" : "Coach", handleChange, lang, coachesStatus)}
                            {SelectForm("riders", riders, selectedRiders, lang === "ua" ? "Всього райдерів" : "Total riders", handleChange, lang)}
                            {SelectForm("beginners", beginners, selectedBeginners, lang === "ua" ? "З них початківців" : "Rookies among them", handleChange, lang)}
                            {SelectForm("duration", duration, selectedDuration, lang === "ua" ? "Загальна тривалість сесії (хв)" : "Total session duration (min)", handleChange)}
                            <Contact variant="outlined" >
                                <Typography justify="center"> {lang === "ua" ? "Ваші контактні дані " : "Your contact details"} </Typography>
                                <InputContainer noValidate >

                                    <Input required value={riderName} name="name" onChange={handleChange} label={lang === "ua" ? "Імʼя" : "Name"} variant="outlined" />
                                    <Input required value={riderPhone} name="phone" onChange={handleChange} label={lang === "ua" ? "Телефон" : "Phone"} variant="outlined" type="tel" />
                                    <Subtitle variant="caption">{lang === "ua" ? "В форматі +3800000000000" : "Format +3800000000000"} </Subtitle>


                                    {riderFound && <Found variant="subtitle1">{lang === "ua" ? "Обліковий запис знайдено" : "Account found"} </Found>}
                                </InputContainer>
                            </Contact>
                            <Button variant="contained" color="primary" onClick={findSessions} disabled={findValidation}>{lang === "ua" ? "Знайти вільні сесії" : "Find sessions"}</Button>
                        </Grid>
                    </Subcontainer>
                    <Subcontainer item xs={10} md={4}>
                        <Summary elevation={2}>
                            {sessionStatus === "Found" && <Grid container direction="column" align="center">
                                <Typography variant="h5" justify="center"> {lang === "ua" ? "Деталі бронювання " : "Booking details"} </Typography>
                                <SummaryTimes item>
                                    <TimeIcon />
                                    {SelectForm("start", startTimes, selectedStart, lang === "ua" ? "Початок" : "Start", handleChange)}
                                    {SelectForm("end", endTimes, selectedEnd, lang === "ua" ? "Кінець" : "End")}
                                </SummaryTimes>

                                <Prices variant="outlined" >
                                    <PriceSet container direction="row" justifyContent="space-between"><Price> {lang === "ua" ? "Дата" : "Date"}:  </Price><Price>{final.date}</Price></PriceSet>
                                    <PriceSet container direction="row" justifyContent="space-between"><Price> {lang === "ua" ? "Катер" : "Boat"}: </Price><Price>{final.boat}</Price></PriceSet>
                                    <PriceSet container direction="row" justifyContent="space-between"><Price> {lang === "ua" ? "Тренер" : "Coach"}: </Price><Price>{final.coach}</Price></PriceSet>
                                    <PriceSet container direction="row" justifyContent="space-between"><Price> {lang === "ua" ? "Всього райдерів" : "Total riders"}: </Price><Price>{final.riders}</Price></PriceSet>
                                    <PriceSet container direction="row" justifyContent="space-between"><Price> {lang === "ua" ? "З них початківців" : "Rookies among them"}: </Price><Price>{final.beginners}</Price></PriceSet>
                                    <PriceSet container direction="row" justifyContent="space-between"><Price> {lang === "ua" ? "Загальна тривалість сесії (хв)" : "Total session duration (min)"}: </Price><Price>{final.duration} {lang === "ua" ? "хвилин" : "minutes"}</Price></PriceSet> <br></br>
                                    <PriceSet container direction="row" justifyContent="space-between"><Price> {lang === "ua" ? "Імʼя" : "Name"}: </Price><Price>{final.name}</Price></PriceSet>
                                    <PriceSet container direction="row" justifyContent="space-between"><Price> {lang === "ua" ? "Телефон" : "Phone"}: </Price><Price>{final.phone}</Price></PriceSet>
                                </Prices>

                                <Prices variant="outlined" >

                                    {riderBalance > 0 && <PriceSet container direction="row" justifyContent="space-between"><Price> {lang === "ua" ? "Залишок на рахунку" : "Balance"}:</Price><Price>{riderBalance} {lang === "ua" ? "грн" : "UAH"}</Price></PriceSet>}
                                    {riderBalance > 0 && <br></br>}
                                    {selectedStart === "" && <PriceSet container direction="row" jujustifyContent="space-between"><Price>{lang === "ua" ? "Для остаточної ціни оберіть початок сесії" : "Choose a session start for a final price"} </Price></PriceSet>}
                                    {boatPrice > 0 && <PriceSet container direction="row" justifyContent="space-between"><Price>{lang === "ua" ? "Вартість катера" : "Boat price"}: </Price><Price>{boatPrice} {lang === "ua" ? "грн" : "UAH"}</Price></PriceSet>}
                                    {coachPrice > 0 && <PriceSet container direction="row" justifyContent="space-between"><Price> {lang === "ua" ? "Вартість тренера" : "Coach price"}: </Price><Price>{coachPrice} {lang === "ua" ? "грн" : "UAH"}</Price></PriceSet>}
                                    {riderBalance > 0 && coachPrice + boatPrice > 0 && <PriceSet container direction="row" justifyContent="space-between"><Price><b>{lang === "ua" ? "Повна вартість" : "Total price"}:</b>  </Price><Price>{coachPrice + boatPrice} {lang === "ua" ? "грн" : "UAH"}</Price></PriceSet>}
                                    <br></br>
                                    {totalPrice > 0 && <PriceSet container direction="row" justifyContent="space-between"><Price>{lang === "ua" ? "Формат оплати" : "Payment type"}:  </Price>
                                        <Price>
                                            <FormControl style={{ marginTop: "-9px" }}>
                                                <RadioGroup

                                                    value={paymentType}
                                                    name="payment-type"
                                                    onChange={handleChange}
                                                >
                                                    <FormControlLabel style={{ marginRight: "unset" }} value="full" control={<Radio color="primary" />} label={lang === "ua" ? "Повна оплата" : "Full payment"} />
                                                    <FormControlLabel style={{ marginRight: "unset" }} value="part" control={<Radio color="primary" />} label={lang === "ua" ? "Передоплата 50%" : "50% prepayment"} />

                                                </RadioGroup>
                                            </FormControl>
                                        </Price>
                                    </PriceSet>}


                                    <br></br>
                                    <PriceSet container direction="row" justifyContent="space-between"><Price><b> {lang === "ua" ? "До сплати" : "To pay"}:</b></Price><Price><b>{totalPrice} {lang === "ua" ? "грн" : "UAH"}</b></Price></PriceSet>
                                </Prices>

                                <TextField multiline style={{ "margin": "0", "marginTop": "12px", "width": "100%" }} value={comment} name="comment" onChange={handleChange} label={lang === "ua" ? "Коментар до бронювання (необовʼязково)" : "Booking comment (optional)"} variant="outlined" />
                                {comment.length > 0 &&
                                    <Typography variant="caption" display="block" gutterBottom align='right'>
                                        {comment.length}/300
                                    </Typography>
                                }
                                <Book
    variant="contained"
    onClick={order}
    disabled={!selectedStart}
>
    {lang === "ua" ? "Забронювати" : "Book"}
</Book>
{checkoutError && (
    <Typography variant="caption" color="error" display="block" gutterBottom>
        {checkoutError}
    </Typography>
)}
                                {discrepancy && <DiscrepancyText variant="caption" display="block" gutterBottom>{lang === "ua" ? 'Деякі дані відрізняються від обраних. Щоб знайти актуальні сесії - нажміть "Знайти вільні сесії".' : 'Some fields were changed. To see relevant sessions - press "Find sessions"'} </DiscrepancyText>}
                            </Grid>}

                            {sessionStatus === "Not Found" && <Grid container direction="column" align="center">

                                <NotFoundTitle variant="h5" justify="center" gutterBottom> {lang === "ua" ? "По заданним параметрам немає вільної сесії" : "There are no available sessions"} </NotFoundTitle>
                                <NotFoundSubtitle justify="center"> {lang === "ua" ? "Спробуйте змінити один із фільтрів або обрати інший день" : "Try to change one of the filters or choose another day"}</NotFoundSubtitle>
                            </Grid>}

                            {sessionStatus === "Not Yet Found" && <Grid container direction="column" align="center">

                                <NotFoundTitle variant="h5" justify="center" gutterBottom> {lang === "ua" ? "Оберіть параметри для пошуку сесії" : "Pick options to find a session"}  </NotFoundTitle>
                                <NotFoundSubtitle justify="center">{lang === "ua" ? "Оберіть доступний день в календарі та бажані фільтри для пошуку сесії" : "Pick a day in the calendar and relevant options to find a session"}</NotFoundSubtitle>
                            </Grid>}

                            {sessionStatus === "Looking" && <Grid container direction="column" align="center">

                                <NotFoundTitle variant="h5" justify="center" gutterBottom> {lang === "ua" ? "Шукаємо сесію" : "Looking for a session"}  </NotFoundTitle>
                                <NotFoundSubtitle justify="center">{lang === "ua" ? "Будь ласка, зачекайте, поки ми знайдемо сесію за вашими параметрами" : "Please wait while we find a session based on your parameters"} </NotFoundSubtitle>
                                <div style={{ display: "block", marginBottom: "10px" }}><CircularProgress /></div>

                            </Grid>}

                            {sessionStatus === "Error" && <Grid container direction="column" align="center">

                                <NotFoundTitle variant="h5" justify="center" gutterBottom>{lang === "ua" ? "Щось пішло не так" : "Something went wrong"} </NotFoundTitle>
                                <NotFoundSubtitle justify="center">{lang === "ua" ? "Будь ласка, спробуйте ще раз пізншіе" : "Please, try again later"}  </NotFoundSubtitle>
                            </Grid>}
                        </Summary>

                    </Subcontainer>

                    <Dialog
                        open={successfullyBooked}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={event => handleClose("success")}
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle>{lang === "ua" ? "Сесія успішно заброньована та оплачена" : "Session was successfully booked and payed"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                                {lang === "ua" ? "Дякуємо за покупку!" : "Thank you for your purchase!"}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={event => handleClose("success")}>ОК</Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={errorBooking}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={event => handleClose("error")}
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle>{lang === "ua" ? "Щось пішло не так" : "Something went wrong"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                                {lang === "ua" ? "Будь ласка, спробуйте ще раз пізншіе" : "Please, try again later"}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={event => handleClose("error")}>ОК</Button>
                        </DialogActions>
                    </Dialog>


                    <Snackbar
                        open={coachAlert}
                        onClose={event => handleClose("coachAlert")}
                        TransitionComponent={Transition}
                        message={lang === "ua" ? "Упс! Якщо ви тренуєтесь вперше - необхідно обрати тренера. Повірте, так буде краще!" : "Oops! If you're training for the first time, you need to choose a coach. Trust us, it will be better!"}
                        key="aaa"
                        autoHideDuration={3000}
                    />
                </Container>
            }
            {show === "Checkout" && <GoBack onClick={() => setShow("Tool")}><GoBackLink>⬅ {lang === "ua" ? "Повернутися до вибору" : "Go back to options"}</GoBackLink></GoBack>}
            <div id="checkout-container" style={show === "Tool" ? { display: "none" } : { display: "block" }}></div>

        </LocalizationProvider >
    );
}

// function SelectForm(arrayName, arrayVar, value, name, change) {
//     return (
//         <SummaryTimePicker variant="outlined" width={name === "Початок" || name === "Кінець" || name === "Start" || name === "End" ? "35%" : "100%"}>


//             <InputLabel id="demo-simple-select-label">{name}</InputLabel>

//             <TimeSelect
//                 value={value}
//                 label={name}
//                 onChange={change}
//                 name={arrayName}
//                 disabled={name === "Кінець" || name === "End"}

//             >
//                 {arrayVar.map((item, index) => {
//                     if (item.name) {
//                         return <MenuItem value={item.id} key={index} >{item.name}</MenuItem>
//                     }
//                     return <MenuItem value={item} key={index}>{item}</MenuItem>
//                 })}
//             </TimeSelect>


//         </SummaryTimePicker>
//     )
// }

// Usage


function SelectForm(arrayName, arrayVar, value, name, change, lang, status) {
    const tooltipTexts = {
        "boats": (lang === "ua" ? "В нашому клубі є катери різних моделей, обери свій улюблений. Дізнатись більше про катери можна на сайті" : "Our club has boats of different models, choose your favorite. You can learn more about the boats on the website"),
        "coaches": (lang === "ua" ? "Обери свого тренера для ефективного тренування. Якщо ти тренуєшся вперше - заняття проходить за участю тренера, це обов'язково і повір - продуктивно та безпечно ;)" : "Choose your coach for an effective training. If you are training for the first time - the lesson is held with the participation of a trainer, it is mandatory and believe me - productive and safe ;)"),
        "riders": (lang === "ua" ? "Вкажіть кількість людей що будуть катати" : "Specify the number of people who will ride"),
        "beginners": (lang === "ua" ? "Вкажіть кількість райдерів, що будуть вперше займатись вейсерфом. Це дуже допоможе тренеру підготуватись до тренування, розрахувати час та провести інструктаж. " : "Indicate the number of riders who will be going wakesurfing for the first time. This will help the coach to prepare for the training, calculate the time and give instructions. "),
    };
    const isSpecialName = Object.keys(tooltipTexts).includes(arrayName);
    const labelWithTooltip = isSpecialName ? `${name} (*)` : name;
    const tooltipTitle = isSpecialName ? tooltipTexts[arrayName] : '';

    return (
        <SummaryTimePicker variant="outlined" width={name === "Початок" || name === "Кінець" || name === "Start" || name === "End" ? "35%" : "100%"}>
            {isSpecialName && (
                <Tooltip title={tooltipTitle} interactive="true"
                    enterTouchDelay={0}
                    leaveTouchDelay={5000}>
                    <InputLabel id="demo-simple-select-label">{labelWithTooltip}</InputLabel>
                </Tooltip>
            )}

            {!isSpecialName && (
                <InputLabel id="demo-simple-select-label">{name}</InputLabel>

            )}

            <TimeSelect
                value={value}
                label={labelWithTooltip}
                onChange={change}
                name={arrayName}
                disabled={name === "Кінець" || name === "End" || status === "No coaches" || status === "Getting boats"}
            >
                {arrayVar.map((item, index) => {
                    if (item.name) {
                        return <MenuItem value={item.id} key={index} >{item.name}</MenuItem>;
                    }
                    return <MenuItem value={item} key={index}>{item}</MenuItem>;
                })}
            </TimeSelect>
            {status?.length > 0 && <FieldSubtitle variant="subtitle2" gutterBottom>
                {status === "Getting coaches" && `${lang === "ua" ? "Список доступних тренерів завантажується" : "The list of available coaches is loading"}`}
                {status === "No coaches" && `${lang === "ua" ? "Упс! Схоже в цей час вже немає вільного тренера ;( Давай спробуємо обрати інший день." : "Oops! It looks like there's no coach available at this time ;( Let's try to choose another day."}`}
                {status === "Getting boats" && `${lang === "ua" ? "Список доступних катерів завантажується" : "The list of available boats is loading"}`}

            </FieldSubtitle>}

        </SummaryTimePicker>
    );
}